import CallbackModal from "components/callback-modal";
import { useState } from "react";
import MD from "react-markdown";
import Link from "next/link";
import I from "next/image";

export default function Video({
  fields,
  first = false,
  sys,
  includes,
  primary,
  event,
  events,
}) {
  const titleParts = fields.title && fields.title.split(" ");
  const last = titleParts.pop();
  const Title = ({ children }) => {
    return first ? <h1>{children}</h1> : <h3>{children}</h3>;
  };
  const [state, setState] = useState({
    faqs: false,
    courses: false,
    showCallbackModal: false,
  });

  const images = fields.images;

  return (
    <section>
      <div className="relative isolate">
        <div
          className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
          aria-hidden="true"
        >
          <div
            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#8affed] to-[#4cb3f4] opacity-50"
            style={{
              clipPath:
                "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
            }}
          />
        </div>
        <div className="overflow-hidden">
          <div className="mx-auto max-w-7xl px-6 pb-32 pt-8 sm:pt-60 lg:px-8 lg:pt-12">
            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
              <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                {fields.title && (
                  <Title>
                    <span className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                      <span className="inline text-gray-900">
                        {titleParts.join(" ")}
                      </span>
                      {last && (
                        <span className="inline text-brand-600"> {last}</span>
                      )}
                    </span>
                  </Title>
                )}
                {fields.text && (
                  <div className="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none prose">
                    <MD
                      components={{
                        strong: (props) => {
                          return (
                            <strong {...props} className="text-brand-600" />
                          );
                        },
                      }}
                    >
                      {fields.text}
                    </MD>
                  </div>
                )}
                <div className="mt-10 flex flex-col space-y-5 sm:space-y-0 sm:flex-row items-center gap-x-6">
                  <Link
                    href="/courses"
                    className="w-full md:w-max rounded-md bg-brand-600 px-6 py-4 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 outline outline-2 outline-offset-2 outline-brand-600 gradient-border transition-colors duration-200 hover:outline-brand-500 text-center"
                  >
                    <span className="relative z-[1] text-center">
                      Browse Training Courses
                    </span>
                  </Link>
                  <Link
                    href="/request-a-callback"
                    className="w-full md:w-max group rounded-md text-sm font-semibold px-6 py-4 leading-6 eading-6 text-gray-600 ring-2 ring-gray-900/10 hover:ring-brand-600 shadow-lg shadow-brand-100 transition-all duration-200 hover:shadow-lg hover:shadow-brand-200 text-center"
                  >
                    Speak With An Advisor{" "}
                    <span
                      aria-hidden="true"
                      className="group-hover:pl-1 transition-all duration-150"
                    >
                      →
                    </span>
                  </Link>
                  {/* <button
                    type="button"
                    onClick={() => {
                      setState({ ...state, showCallbackModal: true });
                    }}
                    className="w-full md:w-max group rounded-md text-sm font-semibold px-6 py-4 leading-6 eading-6 text-gray-600 ring-2 ring-gray-900/10 hover:ring-brand-600 shadow-lg shadow-brand-100 transition-all duration-200 hover:shadow-lg hover:shadow-brand-200 text-center"
                  >
                    Speak with an advisor{" "}
                    <span
                      aria-hidden="true"
                      className="group-hover:pl-1 transition-all duration-150"
                    >
                      →
                    </span>
                  </button> */}
                </div>
              </div>
              <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                  <div className="relative">
                    <I
                      src={`https:${images[0].fields.file.url}`}
                      alt={images[0].fields.description}
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      width={400}
                      height={528}
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                  <div className="relative">
                    <I
                      src={`https:${images[1].fields.file.url}`}
                      alt={images[1].fields.description}
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      width={400}
                      height={528}
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="relative">
                    <I
                      src={`https:${images[2].fields.file.url}`}
                      alt={images[2].fields.description}
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      width={400}
                      height={528}
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                  <div className="relative">
                    <I
                      src={`https:${images[3].fields.file.url}`}
                      alt={images[3].fields.description}
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      width={400}
                      height={528}
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="relative">
                    <I
                      src={`https:${images[4].fields.file.url}`}
                      alt={images[4].fields.description}
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      width={400}
                      height={528}
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {state.showCallbackModal && (
        <CallbackModal
          event={event}
          showCallbackModal={state.showCallbackModal}
          setShow={setState}
        />
      )}
    </section>
  );
}
