import dynamic from "next/dynamic";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Sentry from "@sentry/nextjs";
import { recordConversion, Conversion } from "utils/conversions";
import { klayAction } from "utils/klay";
import { useSession } from "./providers/session";

const Modal = dynamic(() => import("components/molecules/modal"));

type Props = {
  showCallbackModal: boolean;
  setShow: any;
  [key: string]: any;
};

export default function CallbackModal({
  showCallbackModal,
  setShow,
  entry = null,
  event = null,
  eventCategory = null,
  type = "callback",
  title = "Schedule a call with one of our course advisors",
  user,
}: Props) {
  console.groupCollapsed("CallbackModal");
  console.log("event", event);
  console.log("eventCategory", eventCategory);
  console.log("entry", entry);
  console.groupEnd();
  const [state, setState] = useState({
    complete: false,
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (user) {
      setValue("first_name", user?.first_name);
      setValue("last_name", user?.last_name);
      setValue("email", user?.email);
      setValue("phone_number", user?.contact_number);
    }
  }, [user]);

  const session = useSession();

  const courses =
    session && session.selectedCourses && session.selectedCourses.length > 0
      ? session.selectedCourses.map((course) => {
          return { id: course.event.id, title: course.fields.title };
        })
      : [];

  const onSubmit = async (data) => {
    try {
      console.log("data", data, entry, event);
      const ProductID = event ? event.id : null;
      const ProductName = event
        ? event.title
          ? event.title
          : event.events && event.events.length > 0
          ? event.events[0].detail.title
          : null
        : null;

      const req = await fetch("/api/callback/", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...data,
          courses,
          type,
        }),
      });

      const res = await req.json();

      if (res && res.success) {
        recordConversion({
          send_to: Conversion.callback,
        });

        (window as any).fbq && (window as any).fbq("track", "Contact");

        (window as any).heap &&
          (window as any).heap.addUserProperties({
            ...data,
          });

        (window as any).heap &&
          (window as any).heap.track("Callback Request", {
            ...data,
          });

        // @ts-ignore
        klaviyo &&
          // @ts-ignore
          klaviyo.push([
            "identify",
            {
              $email: data.email,
              $first_name: data.first_name,
              $last_name: data.last_name,
              $phone_number: data.phone_number,
            },
          ]);

        const $event_id = event ? event.id : null;
        const $event_price = event ? event.standardPrice / 100 : null;
        const params = {
          $email: data.email,
          $first_name: data.first_name,
          $last_name: data.last_name,
          $phone_number: data.phone_number,
          $path_name: window.location.pathname,
          $event_id,
          $event_price,
          ProductID,
          ProductName,
          URL: window.location.href,
          ProductURL: window.location.href,
          ...(entry ? klayAction(entry) : {}),
        };

        // @ts-ignore
        klaviyo &&
          // @ts-ignore
          klaviyo.push(["track", "Callback Request", params]);
      }

      setState({ ...state, complete: true });
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  };

  return (
    <Modal
      open={showCallbackModal}
      close={() => {
        setShow({ ...state, showCallbackModal: false });
        document.body.style.overflow = "auto";
      }}
      full={state.complete}
      xlarge={!state.complete}
    >
      <div
        className={state.complete ? "w-full mx-auto h-full" : "w-full mx-auto"}
      >
        {state.complete && (
          <div className="h-full">
            <div className="bg-white">
              <div className="max-w-7xl mx-auto h-full">
                <iframe
                  src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ28xd3NPsZQJIxeuaTCep2WJENG0LOYVTLTDZchVdRmkT3mhwe09k6LPa8iuTBuXaUEZY0iYOBQ?gv=true"
                  style={{ border: 0 }}
                  width="100%"
                  height="700"
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
          </div>
        )}

        {!state.complete && (
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-3 px-8">
            <h3 className="text-xl font-semibold text-center py-1">{title}</h3>
            <div className="flex flex-row space-x-4 w-full">
              <div className="block flex-1">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First name
                </label>
                <input
                  id="name"
                  name="first_name"
                  type="text"
                  placeholder="Enter your name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
                  {...register("first_name", { required: true })}
                />
                {errors.first_name && (
                  <p className="text-red-500 text-sm font-medium mt-1">
                    Name is required
                  </p>
                )}
              </div>

              <div className="block flex-1">
                <label
                  htmlFor="surname"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last name
                </label>
                <input
                  id="surname"
                  name="last_name"
                  type="text"
                  placeholder="Enter your surname"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
                  {...register("last_name", { required: true })}
                />
                {errors.last_name && (
                  <p className="text-red-500 text-sm font-medium mt-1">
                    Surname is required
                  </p>
                )}
              </div>
            </div>

            <div className="block">
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Phone Number
              </label>
              <input
                id="phone"
                name="phone"
                type="tel"
                placeholder="Enter your phone number"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
                {...register("phone_number", { required: true })}
              />
              {errors.phone_number && (
                <p className="text-red-500 text-sm font-medium mt-1">
                  Phone number is required
                </p>
              )}
            </div>
            <div className="block">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Enter your email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <p className="text-red-500 text-sm font-medium mt-1">
                  Email is required
                </p>
              )}
            </div>
            <div className="pt-1">
              <button
                type="submit"
                className="mt-4 w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-md text-white bg-brand-600 hover:bg-brand-900"
                disabled={false}
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
}
